import { EntityId } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { uploadsSelectors } from "~/redux/state/uploads"
import { RootState } from "~/redux/store"
import { usePageSession } from "./usePageSession"

export const useUploads = (ids?: EntityId[]) => {
  const {
    pageSession: { uploadIds },
  } = usePageSession()

  const hasLegitParam = Boolean(ids) && ids!.length > 0

  const uploads = useSelector<RootState, UploadEntity[]>(state =>
    uploadsSelectors.selectByIds(state, hasLegitParam ? ids! : uploadIds)
  )

  return { uploads }
}
