import { Box, Flex, Image } from "@chakra-ui/react"
import {
  AppEntityHydrated,
  BlockOrderThankYouEntityHydrated,
} from "@jackfruit/common"
import React from "react"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useOrder } from "~/hooks/useOrder"
import OrderThankYouText from "../thank-you/OrderThankYouText"
import Wrapper from "../Wrapper"

export interface Props {
  config: BlockOrderThankYouEntityHydrated
  appConfig: AppEntityHydrated
}

const OrderThankYouBlock: React.FC<Props> = ({ config, appConfig }) => {
  const { lastOrderId } = useApplicationState()
  const { order: placedOrder } = useOrder(lastOrderId)

  if (!placedOrder) {
    return null
  }

  const { title, body } = config

  // nonce has already been used and generic thank you page should be shown
  const isFakeOrder = placedOrder.id === 1

  if (isFakeOrder) {
    return (
      <Wrapper py={8}>
        <Flex
          justify="space-between"
          align="center"
          aria-label="thankyou-wrapper"
          bgColor={"#ECECEC"}
        >
          <Box display={{ base: "none", md: "block" }} w={24}>
            <Box
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="cover"
              bgImage="url('images/ThankYouPageBannerLeft.png')"
              w={100}
            />
          </Box>

          <Box textAlign="center">
            <OrderThankYouText
              appConfig={appConfig}
              placedOrder={placedOrder}
              title={title}
              body={" "}
            />
          </Box>

          <Box display={{ base: "none", md: "block" }} w={24}>
            <Box
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="cover"
              bgImage="url('images/ThankYouPageBannerRight.png')"
              w={100}
            />
          </Box>
        </Flex>
      </Wrapper>
    )
  }

  return (
    <Wrapper pt={8} pb={{ base: 6, md: 8 }}>
      <Flex
        justify="space-between"
        align="center"
        mx={{ lg: 10, "2xl": 28 }}
        aria-label="thankyou-wrapper"
        bgColor={"#ECECEC"}
        borderRadius={"0.625rem"}
        bgPosition={{
          base: "50% 100%",
          sm: "50% 85%",
          md: "50% 55%",
          lg: "center",
        }}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgImage={{
          base: "url('images/ThankYouPageBannerMobile.png')",
          md: "url('images/ThankYouPageBannerTablet.png')",
          lg: "none",
        }}
      >
        <Box
          display={{ base: "none", lg: "block" }}
          w={{ lg: "23%", xl: "17%" }}
        >
          <Image
            src="images/ThankYouPageBannerLeft.png"
            alt="ThankYouPageBannerLeft"
            w={"100%"}
            h={"auto"}
          />
        </Box>

        <Box
          textAlign="center"
          width={{ base: "95%", md: "80%", lg: "54%", xl: "52%" }}
          mx={"auto"}
        >
          <OrderThankYouText
            appConfig={appConfig}
            placedOrder={placedOrder}
            title={title}
            body={body}
          />
        </Box>

        <Box
          display={{ base: "none", lg: "block" }}
          w={{ lg: "23%", xl: "17%" }}
        >
          <Image
            src="images/ThankYouPageBannerRight.png"
            alt="ThankYouPageBannerRight"
            w={"100%"}
            h={"auto"}
          />
        </Box>
      </Flex>
    </Wrapper>
  )
}

export default OrderThankYouBlock
